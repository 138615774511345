import '../../../assets/scss/common.scss'

import { faBan, faCheck } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function StatusPills(props: { type: string }) {
    const { type } = props;

    return (
        <>
            {type.toLowerCase() === 'enabled' && <div className="pill green"><FontAwesomeIcon icon={faCheck} /></div>}
            {type.toLowerCase() === 'disabled' && <div className="pill red"><FontAwesomeIcon icon={faBan} /></div>}
        </>
    )
}
