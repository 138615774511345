import { useEffect, useState } from "react"

import { callBackEnd } from "../utils/backEndUtils";

export default function useOptions() {

    const [options, setOptions] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        async function getOptions() {
            try {
                setLoading(true);
                const response = await callBackEnd('portal', 'get', 'static/options');

                if (Array.isArray(response)) {
                    setOptions(response);
                    setError(null);
                } else {
                    throw new Error('Invalid response from API');
                }
            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }
        getOptions().catch((error) => { console.error(error) });
    }, [])

    return { options, loading, error }
}
