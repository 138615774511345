import { useEffect, useState } from "react";

import { callBackEnd } from "../utils/backEndUtils";

type StatusMessage = {
    message: string,
    type: string,
    display: boolean
}

export default function useUserInfos(userId: string) {
    const [userInfos, setUserInfos] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);

    const formatStatus = (status: string): StatusMessage => {
        switch (status) {
            case 'REGISTERED':
                return {
                    message: 'Your account is pending approval from an administrator',
                    type: 'info',
                    display: true
                };
            case 'NAMESPACE_JOIN_REQUESTED':
                return {
                    message: 'Your account is pending approval from a namespace administrator',
                    type: 'info',
                    display: true
                };
            case 'VERIFIED':
                return {
                    message: 'Your account is active',
                    type: 'success',
                    display: false
                };
            case 'REJECTED':
                return {
                    message: 'Your account has been rejected',
                    type: 'warning',
                    display: true
                };
            default:
                return {
                    message: '',
                    type: '',
                    display: false
                };
        }
    }

    useEffect(() => {
        async function getNews() {
            try {
                setLoading(true);
                const response: any = await callBackEnd('portal', 'get', `users/${userId}`);

                response.formattedContent = {
                    status: formatStatus(response.status),
                }

                setUserInfos(response);
                setError(null);
            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }
        if (userId) getNews().catch((e: any) => { console.log(e) });

    }, [userId]);

    return { userInfos, loading, error };
}