import { useCallback, useEffect, useState } from "react";

import { callBackEnd } from "../utils/backEndUtils";
import { useOidcAccessToken } from './../utils/secure';

export default function useNamespaceById() {
    const { accessTokenPayload } = useOidcAccessToken();
    const namespaceId: string = accessTokenPayload?.namespaceId;

    const [namespace, setNamespace] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);


    const getNamespaceUsersCount = useCallback(async (): Promise<any> => {
        const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/users`)
            .catch((e: any) => { throw new Error(e) });
        if (Array.isArray(response)) {
            return response.length;
        } else {
            throw new Error('Invalid response from API');
        }
    }, [namespaceId]);

    const getNamespacePackagesCount = useCallback(async (): Promise<any> => {
        const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/packages`)
            .catch((e: any) => { throw new Error(e) });
        if (Array.isArray(response)) {
            return response.length;
        } else {
            throw new Error('Invalid response from API');
        }
    }, [namespaceId]);

    const getNamespacePackages = useCallback(async ({ withVolumes }: { withVolumes: boolean }): Promise<any> => {
        //get packages

        const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/packages`)
            .catch((e: any) => { throw new Error(e) });
        let packages: any[] = [];

        if (Array.isArray(response)) {
            packages = response;

            if (withVolumes) {
                //get volumes for each package
                for (const packageItem of packages) {
                    const packageId = packageItem.uid;
                    const volumesResponse: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/packages/${packageId}/volumes`)
                        .catch((e: any) => { throw new Error(e) });
                
                    packageItem.volumes = volumesResponse;
                }
            }

            return packages;
        } else {
            throw new Error('Invalid response from API');
        }

    }, [namespaceId]);

    const getNamespacePackage = useCallback(async ({ packageId }: { packageId: string }): Promise<any> => {
        //get packages

        const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/packages/${packageId}`)
            .catch((e: any) => { throw new Error(e) });

        return response;

    }, [namespaceId]);

const getNamespaceUsers = useCallback(async (): Promise<any> => {
    //get packages
    const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/users`)
        .catch((e: any) => { throw new Error(e) });

    if (Array.isArray(response)) {
        return (response);
    } else {
        throw new Error('Invalid response from API');
    }

}, [namespaceId]);

const getNamespaceOwnerName = useCallback(async (): Promise<any> => {
    const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/owner`)
        .catch((e: any) => { throw new Error(e) });
    if (response) {
        return response.name;
    } else {
        throw new Error('Invalid response from API');
    }
}, [namespaceId]);

    const getNamespaceServiceAccounts = useCallback(async (): Promise<any> => {
        //get packages
        const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/service-accounts`)
            .catch((e: any) => { throw new Error(e) });

        if (Array.isArray(response)) {
            return (response);
        } else {
            throw new Error('Invalid response from API');
        }

    }, [namespaceId]);

useEffect(() => {
    async function getNamespace(namespaceId: string) {
        try {
            setLoading(true);
            const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}`);
            setNamespace(response);
        } catch (error: any) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }
    if (namespaceId) {
        getNamespace(namespaceId);
    }
}, [namespaceId]);



return {
    namespace,
    loading,
    error,
    getNamespaceUsersCount,
    getNamespacePackagesCount,
    getNamespaceOwnerName,
    getNamespacePackages,
    getNamespaceUsers,
    getNamespacePackage,
    getNamespaceServiceAccounts
};
}
