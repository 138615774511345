import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import React, { useEffect, useState } from 'react'

const BarChartSplittedCustom = (props: any) => {
    const { handleChartData, mergedData, runIndex } = props;

    const [barData, setBarData] = useState<any>(null);



    useEffect(() => {
        const buildStats = (mergedData: any) => {
            let stats_hit: any = [];
            let stats_nohit: any = [];
            mergedData.forEach((res: any) => {

                if (res?.query?.modelValidation === undefined || res?.query?.modelValidation?.expected === undefined || res?.query?.modelValidation?.category === undefined) {
                    return;
                }

                let stats: any = null;
                if (res.query.modelValidation.expected === "hit") {
                    stats = stats_hit;
                } else {

                    stats = stats_nohit;
                }
                // if (res.modelValidation.matchesNok.length) {
                stats = stats_nohit;
                // }

                if (stats !== null) {
                    let element = stats.find(function (el: any) {
                        return el.category === res.query.modelValidation.category;
                    });

                    const status = res.modelValidation.status;

                    if (element === undefined) {
                        stats.push({
                            [status + "Count"]: 0,
                            "category": res.query.modelValidation.category,
                            "perfect": 0,
                            "high": 0,
                            "medium": 0,
                            "low": 0,
                        })
                        element = stats.at(-1);
                    }


                    if (element !== undefined) {
                        element[status + "Count"]++;
                        element["perfect"] += Number(res.modelValidation.efficiencyMap.perfect);
                        element["high"] += Number(res.modelValidation.efficiencyMap.high);
                        element["medium"] += Number(res.modelValidation.efficiencyMap.medium);
                        element["low"] += Number(res.modelValidation.efficiencyMap.low);
                    }
                }
                const mergedStats = stats_nohit;

                setBarData(mergedStats);

                /**
                 * Building the data for the chart
                 */

                if (mergedStats.length) {
                    handleChartData(runIndex, mergedStats, 'efficiency');
                }
                else {
                    handleChartData(runIndex, null);
                }
            });
        }
        if (mergedData !== undefined) {
            buildStats(mergedData);
        }
    }, [mergedData, runIndex, handleChartData])




    return (
        <>
            {(props?.mergedData !== undefined) ?
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        data={barData}
                        margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="category" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="perfect" stackId="a" fill="#4ade80" />
                        <Bar dataKey="high" stackId="a" fill="#facc15" />
                        <Bar dataKey="medium" stackId="a" fill="#fb923c" />
                        <Bar dataKey="low" stackId="a" fill="#f87171" />
                    </BarChart>
                </ResponsiveContainer>
                : <p>No data</p>}
        </>
    )
}


export default React.memo(BarChartSplittedCustom);