import { useEffect, useState } from 'react';

import LoadingSkeleton from "../Layout/LoadingSkeleton";
import { Table } from "antd";

export default function TopCategories(props: { topCategories: Promise<any> }) {
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await props.topCategories;
                setData(result);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [props.topCategories]);


    const columns = [
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            sorter: (a: any, b: any) => a.category.localeCompare(b.category),
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            sorter: (a: any, b: any) => a.count - b.count,
            defaultSortOrder: 'descend' as const
        }
    ];


    return loading ? <LoadingSkeleton /> : <Table dataSource={data} pagination={false} columns={columns} scroll={{ x: 'max-content' }} />;

}
