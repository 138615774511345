import { ReactNode, useEffect, useState } from "react";

import { createContext } from "react";

interface ConfigProviderProps {
    children: ReactNode;
    config?: ConfigType;
    loading?: boolean;
}

export const ConfigContext = createContext<ConfigProviderType | null>(null);

const ConfigContextProvider: React.FC<ConfigProviderProps> = ({ children }) => {

    const [config, setConfig] = useState<ConfigType | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchConfig = async () => {
            try {
                const response = await fetch('/config.json');
                const data = await response.json();
                setConfig(data);
            } catch (error) {
                console.error('Failed to fetch config:', error);
                throw error;
            } finally {
                setLoading(false);
            }
        };

            fetchConfig();
    }, []);

    return (
        <ConfigContext.Provider value={{ config, loading }}>
            {children}
        </ConfigContext.Provider>
    )
}

export default ConfigContextProvider;