import './../../assets/scss/News.scss';

import { Card, Col, Input, Row } from 'antd';
import { HomeOutlined, SearchOutlined } from '@ant-design/icons';
import { filterNews, showEnvPill } from './utils';

import { Content } from 'antd/es/layout/layout';
import LoadingSkeleton from '../Layout/LoadingSkeleton';
import MotionBox from "../Layout/Animated/MotionBox";
import NewsCard from './NewsCard';
import { PageHeader } from '../Layout/header/PageHeader';
// import SectionBanner from '../Layout/SectionBanner';
import { SecureWithLogin } from "../../utils/secure";
import ShowError from '../Layout/ShowError';
import useNews from "../../hooks/useNews";
import { useState } from 'react';

export default function News() {
    const { news, loading, error } = useNews('dev'); //loading news
    const [search, setSearch] = useState<string>('');
    const filteredNews = filterNews(news, search);

    return (
        <SecureWithLogin callbackPath="/news" role={[]}>
            {(!loading && error === null) ?
                <MotionBox>
                    <Content className="news">
                        {/* <SectionBanner title="News and updates" type="news" /> */}
                        <PageHeader
                            title='News'
                            subtitle='Dashboard'
                            breadcrumbs={[
                                {title:<span><HomeOutlined/>&nbsp;Home</span>,href: '/',},
                                {title:<span>News</span>}]}
                        />
                        <Row gutter={[24,24]}>
                        <Col xs={24} >
                            <Card>
                                <Input
                                    addonBefore={<SearchOutlined />}
                                    style={{ width: '100%', flex: 1 }}
                                    type="text"
                                    placeholder="Search inside the faq"
                                    name="search"
                                    className="search"
                                    onChange={(e: any) => setSearch(e.target.value)}
                                />
                            </Card>
                            </Col>
                            <Col xs={24} >
                                    <Row gutter={[24,24]} >
                                        {filteredNews.map((n: any) => {
                                                return (
                                                    <Col key={n.id} xs={24} xxl={12}>
                                                        <div className="newsItem"><NewsCard news={n} showEnvPill={showEnvPill} key={n.id} /></div>
                                                    </Col>
                                                )
                                        })}
                                    </Row>
                            </Col>
                            {/* <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={6}>
                                <FaqWidget />
                            </Col> */}
                        </Row>
                        {/* <Flex vertical gap={"middle"} justify='center'>
                            <Input addonBefore={<SearchOutlined />} type="text" placeholder="Search inside the news" name="search" className="search" onChange={(e: any) => setSearch(e.target.value)} />
                            <div className="newsContainer">
                                {filteredNews.map((n: any) => {
                                    return (
                                        <div className="newsItem" key={n.id}>
                                            <NewsCard news={n} showEnvPill={showEnvPill} key={n.id} />
                                        </div>
                                    )
                                })}
                            </div>
                        </Flex> */}
                    </Content>
                </MotionBox>
                :
                <>
                    {loading && <LoadingSkeleton />}
                    {error && <ShowError error={error} />}
                </>
            }
        </SecureWithLogin >
    )
}
