import { Divider, Flex, Progress, ProgressProps, Typography } from "antd";
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer, Tooltip } from "recharts";
import { useCallback, useEffect, useState } from "react";

export default function RadarChartCustom(props: any) {
    const { handleChartData, mergedData, runIndex } = props;
    const [barData, setBarData] = useState<any>(null);

    const twoColors: ProgressProps['strokeColor'] = {
        '0%': '#108ee9',
        '100%': '#87d068',
    };

    const buildStats = useCallback((mergedData: any) => {
        /**
         * Preparing array for chart display
         */
        let stats_hit: any = [];
        // let stats_nohit: any = [];
        mergedData.forEach((res: any) => {

            /**
             * Checks if the record has metadata
             * props : expected, category
             */
            if (res?.query?.modelValidation === undefined || res?.query?.modelValidation?.expected === undefined || res?.query?.modelValidation?.category === undefined) {

                return;
            }

            // let stats = (res.query.modelValidation.expected === "hit") ? stats_hit : stats_nohit;
            let stats = stats_hit;

            let element = stats.find((el: any) => {
                return el.category === res.query.modelValidation.category;
            });

            if (element === undefined) {
                stats.push({
                    "category": res.query.modelValidation.category,
                    "passedCount": 0,
                    "failedCount": 0,
                    "skippedCount": 0,
                    "totalCount": 0
                })
                element = stats.at(-1);
            }
            const status = res.modelValidation.status;
            element[status + "Count"]++;
            element["totalCount"]++;
        })

        /**
         * Building the data for the chart
         */

        // if (stats_hit.length || stats_nohit.length) {
        if (stats_hit.length) {
            const updatedStats: any[] = stats_hit.map((s: any) => {
                s.subject = s.category;
                s.value = Math.round((s.passedCount / s.totalCount) * 100);
                s.fullMark = 100;
                return s;
            });

            setBarData(updatedStats)

            handleChartData(runIndex, updatedStats, 'effectiveness');
        }
        else {
            handleChartData(runIndex, null, '');
        }
    }, [handleChartData, runIndex]);

    useEffect(() => {

        if (mergedData !== undefined) {
            buildStats(mergedData);
        }

    }, [buildStats, mergedData]);

    const getDistinctCategories = (data: any[]) => {
        const categories = new Set();

        data.forEach((d: any) => {
            if (d?.query?.modelValidation?.category) {
                categories.add(d.query.modelValidation.category);
            }
        });

        return Array.from(categories);
    }

    return (
        <>
            {(props?.mergedData !== undefined) ?
                <>
                    {(getDistinctCategories(props?.mergedData).length > 2) ?
                        <ResponsiveContainer width="100%" height="100%">
                            <RadarChart
                                // cx={400}
                                // cy={300}
                                // outerRadius={150}
                                // width={800}
                                // height={600}
                                margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                                data={barData}
                            >

                                <PolarGrid />
                                <PolarAngleAxis dataKey="subject" />
                                <PolarRadiusAxis />
                                <Tooltip />
                                <Radar
                                    name="Effectiveness"
                                    dataKey="value"
                                    stroke="#82b9d6"
                                    fill="#82b9d6"
                                    fillOpacity={0.6}
                                />
                            </RadarChart>
                        </ResponsiveContainer>
                        :

                        <Flex gap="middle" vertical>
                            {barData?.map((d: any) => {
                                return (
                                    <div key={d.category}>
                                        <Typography.Text>{d.category}</Typography.Text>
                                        <Progress percent={d.value} strokeColor={twoColors} />

                                        <Divider />
                                    </div>
                                )
                            })}
                        </Flex>
                    }
                </>
                :
                <p>Not enough data to create a chart.</p>}
        </>
    )
}
