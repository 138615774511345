import '../../../assets/scss/CompareTables.scss';

import { useMemo, useState } from "react";

import { Divider } from 'antd';
import { Typography } from "antd";
import { getEfficiencyScore } from '../utils/mvCalculation';
import { toTitleCase } from '../../../utils/stringUtils';

export default function CompareTable(props: { runsData: any }) {
    const { Title } = Typography;
    const { runsData } = props;
    const [statsHit, setStatsHit] = useState<any[]>([]);
    const [statsNoHit, setStatsNoHit] = useState<any[]>([]);

    // const bestRunProfiles: { id: string, label: string }[] = [
    //     {
    //         id: "bestEffectiveness",
    //         label: "Best effectiveness"
    //     },
    //     {
    //         id: "bestBalanced",
    //         label: "Best balanced"
    //     }
    // ];

    // const getBestRun = (statsHit: any, statsNoHit: any, type: string): any => {
    //     let results: number[] = [];
    //     if (type === "bestEffectiveness") {

    //         const arrCountMaxValue: number[] = get100Percent(statsHit, "effectiveness");

    //         const max: number = Math.max(...arrCountMaxValue);
    //         arrCountMaxValue.forEach((item, index) => item === max ? results.push(index) : null);

    //     } else if (type === "bestBalanced") {
    //         const arrCountMaxValueEffectiveness: number[] = get100Percent(statsHit, "effectiveness");
    //         const arrCountMaxValueEfficiency: number[] = get100Percent(statsNoHit, "efficiency");

    //         const nbRuns: number = runsData.length;
    //         let totals: number[] = [];
    //         for (let i = 0; i < nbRuns; i++) {
    //             totals.push(arrCountMaxValueEffectiveness[i] + arrCountMaxValueEfficiency[i]);
    //         }
    //         const max: number = Math.max(...totals);
    //         totals.forEach((item, index) => item === max ? results.push(index) : null);
    //     }
    //     const label: string | undefined = bestRunProfiles.find(o => o.id === type)?.label;
    //     return `${label} run${results.length > 1 ? 's are' : ' is'} ${results.map((r) => r + 1).join(", ")}`;


    // }

    const getEffectivenessMax = (runs: any) => {
        return Math.max(...runs.map((r: any) => (r.totalCount !== 0) ? Math.round((r.passedCount / r.totalCount) * 100) : 0));
    }
    const getEffiiciencyMax = (runs: any) => {
        return Math.max(...runs.map((r: any) => getEfficiencyScore(r.perfectMatchCount, r.highMatchCount, r.mediumMatchCount, r.lowMatchCount)));
    }
    const getCurrentValueEffectiveness = (run: any) => {
        return Math.round((run.passedCount / run.totalCount) * 100)
    }
    const getCurrentValueEfficiency = (run: any) => {
        return getEfficiencyScore(run.perfectMatchCount, run.highMatchCount, run.mediumMatchCount, run.lowMatchCount)
    }
    const get100Percent = (stats: any, type: string): number[] => {
        let count: number[] = Array(runsData.length).fill(0);
        stats.forEach((s: any) => {
            s.runs.forEach((run: any, rIndex: number) => {
                if (type === 'effectiveness') {
                    if (getCurrentValueEffectiveness(run) === 100) {
                        count[rIndex]++;
                    }
                } else if (type === 'efficiency') {
                    if (getCurrentValueEfficiency(run) === 100) {
                        count[rIndex]++;
                    }
                }
            })
        })

        return count;
    }

    useMemo(() => {
        let runs_statsHit: any[] = [];
        let runs_statsNoHit: any[] = [];

        const calculateStats = (type: "effectiveness" | "efficiency") => {

            runsData.forEach((run: any, runIndex: number) => {
                run.mergedData.forEach((data: any) => {

                    if (data?.modelValidation === undefined || data?.query?.modelValidation?.expected === undefined || data.query?.modelValidation?.category === undefined) {
                        return;
                    }

                    let stats: any = null;
                    if (type === 'effectiveness' && (data.query.modelValidation.expected === "hit" || data.query.modelValidation.expected === "nohit")) {
                        stats = runs_statsHit;
                    }
                    if (type === 'efficiency') {//} && data.modelValidation.matchesNok.length) {
                        stats = runs_statsNoHit;
                    }

                    if (stats !== null) {
                        let element = stats.find(function (el: any) {
                            return el.category === data.query.modelValidation.category;
                        });

                        if (element === undefined) {
                            stats.push({
                                "category": data.query.modelValidation.category,
                                runs: []
                            })
                            element = stats.at(-1);
                        }
                        if (element.runs[runIndex] === undefined) {
                            element.runs[runIndex] = ({
                                "passedCount": 0,
                                "failedCount": 0,
                                "skippedCount": 0,
                                "totalCount": 0,
                                "perfectMatchCount": 0,
                                "highMatchCount": 0,
                                "mediumMatchCount": 0,
                                "lowMatchCount": 0,
                            })
                        }
                        const status = data.modelValidation.status;

                        if (element !== undefined) {
                            element.runs[runIndex][status + "Count"]++;
                            element.runs[runIndex]["perfectMatchCount"] += data.modelValidation.efficiencyMap.perfect;
                            element.runs[runIndex]["highMatchCount"] += data.modelValidation.efficiencyMap.high;
                            element.runs[runIndex]["mediumMatchCount"] += data.modelValidation.efficiencyMap.medium;
                            element.runs[runIndex]["lowMatchCount"] += data.modelValidation.efficiencyMap.low;
                            element.runs[runIndex]['totalCount']++;
                        }
                    }
                })
            });
        }
        calculateStats("effectiveness");
        calculateStats("efficiency");

        setStatsHit(runs_statsHit)
        setStatsNoHit(runs_statsNoHit)
    }, [runsData]);

    const renderTableStats = (stats: any[], type: "effectiveness" | "efficiency") => {
        return (
            <div className="statsTable">
                <Title level={5}>
                    {toTitleCase(type)}
                </Title>
                <table className="customTable mvCompareTable">
                    <thead>
                        <tr>
                            <th>Category</th>
                            {props.runsData.map((run: any, runIndex: number) => (
                                <th key={runIndex}>Run {runIndex + 1}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {stats.map((s: any, index: number) => {
                            return (
                                <tr key={index}>
                                    <td>{s.category}</td>
                                    {s.runs.map((run: any, runIndex: number) => {
                                        //Calculate Maximum value for the run
                                        let max: number = 0;
                                        if (type === "effectiveness") max = getEffectivenessMax(s.runs);
                                        else if (type === "efficiency") max = getEffiiciencyMax(s.runs);

                                        //Calculate the current value
                                        let currentValue: number = 0
                                        if (type === 'effectiveness') currentValue = getCurrentValueEffectiveness(run);
                                        else if (type === "efficiency") currentValue = getCurrentValueEfficiency(run);

                                        //Function to add the class to the cell
                                        const classToAdd = (value: number, max: number): string => {
                                            if (Number.isNaN(value / max)) return "green";

                                            const pc: number = (Math.round((value / max) * 10000) / 100);
                                            if(pc === 100) return "green";
                                            if(pc >= 80) return "lightGreen";
                                            if(pc >= 60) return "orange";
                                            if(pc >= 40) return "yellow";
                                            if(pc >= 20) return "lightRed";
                                            if(pc >= 0) return "red";
                                            return "darkRed";
                                        }

                                        return (
                                            <td key={runIndex} className={`cellBg ${classToAdd(currentValue, max)}`}>
                                                {currentValue}%
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                        {/* Calculation of the total based on the best run strategy */}
                        <tr className="total">
                            <td className="total">Number of 100%</td>
                            {get100Percent((type === 'effectiveness' ? statsHit : statsNoHit), type).map((c: any, cIndex: number) => {
                                return (
                                    <td key={cIndex} className="total">
                                        {c} / {stats.length}
                                    </td>
                                )
                            }
                            )}
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <>
            <div className="tables">
                {renderTableStats(statsHit, "effectiveness")}
                {renderTableStats(statsNoHit, "efficiency")}
            </div>
            <Divider />

            {/* <Flex gap='small' align='center' justify='center'>
                <Typography.Text type='secondary'>
                    {getBestRun(statsHit, statsNoHit, "bestEffectiveness")}
                </Typography.Text>
                |
                <Typography.Text type='secondary'>
                    {getBestRun(statsHit, statsNoHit, "bestBalanced")}
                </Typography.Text>
            </Flex> */}
        </>
    )
}
