import '../../assets/scss/Faq.scss';

import { Card, Col, Flex, Input, Row, Select, Tag, Typography } from 'antd';
import { HomeOutlined, SearchOutlined } from '@ant-design/icons';

import { Content } from 'antd/es/layout/layout';
import { FaqCollapse } from './FaqCollapse';
import LoadingSkeleton from "../Layout/LoadingSkeleton";
import Markdown from 'react-markdown';
import MotionBox from "../Layout/Animated/MotionBox";
import { PageHeader } from '../Layout/header/PageHeader';
import { SecureWithLogin } from "../../utils/secure";
import type { SelectProps } from 'antd';
import ShowError from '../Layout/ShowError';
import { filterFaq } from '../news/utils';
import useFaq from "../../hooks/useFaq";
import { useState } from 'react';

export default function Faq() {
    const { faq, loading, error } = useFaq('dev');
    console.log("🚀 ~ Faq ~ faq:", faq);
    const [search, setSearch] = useState<string>('');
    // const [tags, SetTags] = useState<string[]>([]);
    // State for selected tags
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const options: SelectProps['options'] = [];

    // Populate the options array with tags
    if (!loading && error === null) {
        faq.forEach((item: any) => {
            if (item.tags) {
                item.tags.forEach((tag: string) => {
                    if (!options.some((option) => option.value === tag)) {
                        options.push({
                            label: tag,
                            value: tag,
                        });
                    }
                });
            }
        });
    }

    //filters FAQ based on  search and selected tags
    const filteredFaq = filterFaq(faq, search);
    const filteredFaqByTags = selectedTags.length > 0 ? filteredFaq.filter((item: any) => selectedTags.every((tag: string) => item.tags.includes(tag))) : filteredFaq;

    // Sort the array based on the 'category' property
    const sortedData = [...filteredFaqByTags].sort((a, b) => a.category.localeCompare(b.category));

    // Group the data by category
    const grouped = sortedData.reduce((acc: any, item) => {
        if (!acc[item.category]) {
            acc[item.category] = [];
        }
        acc[item.category].push(item);
        return acc;
    }, {});


    // Event handler for select input change
    const handleChange = (selectedOptions: string[]) => {
        setSelectedTags(selectedOptions);
    };

    return (
        <SecureWithLogin callbackPath="/faq" role={[]}>
            {(!loading && error === null && faq !== null) ?
                <MotionBox>
                    <Content>
                        <PageHeader
                            title='Frequently Asked Questions'
                            subtitle='Dashboard'
                            breadcrumbs={[
                                { title: <span><HomeOutlined />&nbsp;Home</span>, href: '/', },
                                { title: <span>FAQ</span> }]}
                        />
                        <Row gutter={[24, 24]}>
                            <Col xs={24} >
                                <Card style={{ padding: 0 }}>
                                    <Row gutter={[24, 24]}>
                                        <Col xs={24} lg={18} xxl={20}>
                                            <Input
                                                addonBefore={<SearchOutlined />}
                                                style={{ width: '100%', flex: 1 }}
                                                type="text"
                                                placeholder="Search inside the faq"
                                                name="search"
                                                className="search"
                                                onChange={(e: any) => setSearch(e.target.value)}
                                            />
                                        </Col>
                                        <Col xs={24} lg={6} xxl={4}>
                                            <Select
                                                mode="multiple"
                                                allowClear
                                                style={{ minWidth: '150px', width: '100%' }}
                                                placeholder="Select tags to filter"
                                                onChange={handleChange}
                                                options={options}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} >
                                <Card className="sectionCard" title={<h1>Frequently Asked Questions</h1>}>
                                    <Row gutter={[24, 24]}>
                                        {Object.keys(grouped).map((key, f: any, item) => {
                                            return <Col xs={24} key={key}>
                                                <Typography.Title level={4}>{key}</Typography.Title>
                                                <FaqCollapse items={grouped[key].map((item: any, index: number) => {
                                                    return {
                                                        label: <Flex justify='space-between'>
                                                            <span>
                                                                {item.question}
                                                            </span>
                                                            <Flex>
                                                                {item.tags && item.tags.length > 0 && item.tags.map((tag: string) => {
                                                                    return <Tag bordered={false} color="processing" key={tag}>{tag}</Tag>
                                                                })}
                                                            </Flex>
                                                        </Flex>,
                                                        children: <div className="faqContentMD">
                                                            <Markdown>{item.answer}</Markdown></div>,
                                                        key: (index + 1).toString()
                                                    }
                                                })}
                                                    accordion
                                                />
                                            </Col>
                                        })}
                                    </Row>
                                    {/* <FaqCollapse
                                        ghost
                                        accordion
                                        items={Object.keys(grouped).map((item: any, index: number) => {
                                            return { label: item.question, children: <div><Markdown>{item.answer}</Markdown></div>, key: (index + 1).toString() }
                                        })}
                                    /> */}
                                </Card>
                            </Col>
                            {/* <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                                <LatestNewsWidget />
                            </Col> */}
                        </Row>
                    </Content>
                </MotionBox>
                :
                <>
                    {loading && <LoadingSkeleton />}
                    {error && <ShowError error={error} />}
                </>
            }
        </SecureWithLogin >
    )
}
