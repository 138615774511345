import { Badge, Button, Collapse, Divider, Flex } from "antd";
import { useContext, useState } from "react";

import CodeMirror from "@uiw/react-codemirror";
import { EnvContext } from "../../contexts";
import { Link } from "react-router-dom";
import LoadingSkeleton from "../Layout/LoadingSkeleton";
import MotionBox from "../Layout/Animated/MotionBox";
import ReactJson from "@microlink/react-json-view";
import { SecureWithLogin } from "../../utils/secure";
import TryItResultsTable from "./tables/TryItResultsTable";
import { callBackEnd } from "../../utils/backEndUtils";
import { json } from "@codemirror/lang-json";

export default function TryItDeveloper() {
    const env: string = useContext(EnvContext);
    const [jsonValidRibbon, setJsonValidRibbon] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    const [responseCode, setResponseCode] = useState<any>();

    const [code, setCode] = useState<string>(`{
  "options": {
    "threshold": 85,
    "metaScore": false,
    "dobPrecision": 0,
    "cityProximity": 50,
    "dobPolicy": {
      "value": "required"
    },
    "genderPolicy": {
      "value": "required"
    },
    "countryPolicy": {
      "value": "required"
    },
    "cityPolicy": {
      "value": "required"
    }
  },
  "records": [
    {
      "object": "John Doe",
      "metadata": {
        "dobs": [
          "1970-01-01"
        ],
        "gender": "male",
        "places": [
          {
            "country": "US",
            "city": "New York City"
          }
        ]
      }
    }
  ]
}`);

    const handleOnChangeCode = (value: string) => {
        console.log("🚀 ~ handleOnChangeCode ~ value:", value);
        setCode(value);
        setJsonValidRibbon(isValidJSON(value));
    }

    const isValidJSON = (txt: string) => {
        if (txt.length > 0) {
            try {
                JSON.parse(txt);
                return true;
            } catch {
                return false;
            }
        }
        else {
            return false;
        }
    }

    const handleForm = async () => {
        setLoading(true);

        //Call the backend
        const targetEnv: string = (env === 'DEV') ? "DEV" : "SANDBOX";
        callBackEnd(targetEnv, 'POST', `api/v1/jetscan/screen/sanctions`, code)
            .then((response: any) => {

                setResponseCode(response);
                setLoading(false);
            })
            .catch((error: any) => {
                setLoading(false);
                setResponseCode(error);
            })
    }

    return (

        <SecureWithLogin role={['portal_doc', 'js_screen']} callbackPath="/tryIt">
            <MotionBox>

                <Divider orientation="left">API documentation</Divider>
                <p style={{ textAlign: "center" }}>
                    Go to the API documentation
                    {' '}
                    <Link to="/doc/">
                        API documentation
                    </Link>
                    {' '}
                    to learn more about the API and how to use it.
                </p>
                <Divider orientation="left">Your query to the API</Divider>

                <Badge.Ribbon text={jsonValidRibbon ? "Valid JSON" : "Invalid JSON"} color={jsonValidRibbon ? "green" : "red"}>
                    <CodeMirror
                        value={code}
                        minHeight='300px'
                        style={{ border: jsonValidRibbon ? '2px solid rgb(240, 240, 240)' : '2px solid red', borderRadius: '2px' }}
                        onChange={handleOnChangeCode}
                        extensions={[json()]}
                    />
                </Badge.Ribbon>

                <Flex justify="end">
                    <Button type="primary" disabled={!jsonValidRibbon || loading} style={{ marginTop: "20px" }} onClick={handleForm}>
                        Send query to the API
                    </Button>
                </Flex>

                {(responseCode && !loading) &&
                    <>
                        <Divider orientation="left">API Response</Divider>
                        <Collapse
                            defaultActiveKey={['1', '2']}
                            items={[
                                {
                                    key: '1', label: 'Matches Table', children:
                                        <>
                                            {responseCode.results.map((results: any, index: number) => {
                                                return (
                                                    <TryItResultsTable key={index} matches={results.matches} />
                                                )
                                            })}
                                        </>
                                },
                                { key: '2', label: 'API Response', children: <ReactJson src={responseCode} indentWidth={2} /> }
                            ]}
                        />

                    </>
                }
                {loading &&
                    <LoadingSkeleton />
                }

            </MotionBox>
        </SecureWithLogin >
    )
}
