import '../../assets/scss/Sidebar.scss';

import { ApiOutlined, AppstoreOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Divider, Menu, MenuProps } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';

import Sider from 'antd/es/layout/Sider';
import { useAuthInfo } from '../../utils/secure';
import { useFeatures } from '../../hooks/useFeatures';
import { useState } from 'react';

export default function Sidebar(props: any) {
    const [collapsed, setCollapsed] = useState(props.barCollapsed);
    const { isAuthenticated, roles } = useAuthInfo();
    const location = useLocation();
    const { pathname } = location;


    //Initialize items


    // Get features to show in the sidebar
    const { features } = useFeatures();

    const items: MenuProps['items'] = [];

    if (isAuthenticated) {
        features.filter((f: Feature) => f.showSideBar).map((feature: Feature) => {

            const isDisabled: boolean = (feature.enabled) ? (feature.role.length === 0) ? false : !feature.role.every((featureRole: any) => roles.includes(featureRole)) : true;

            items.push({
                label: (isDisabled) ? feature.title : <NavLink to={feature.link}>{feature.title}</NavLink>,
                key: feature.link,
                icon: feature.icon,
                disabled: isDisabled
            });
            return null;
        });
    }
    


    // Check if user is authenticated and is Namespace Admin

    
    // if (isAuthenticated && roles.some((role: any) => ['nsadmin_home'].includes(role))) {
    if (isAuthenticated && roles.some((role: any) => ['nsadmin_packages', 'nsadmin_service_accounts', 'nsadmin_users'].includes(role))) {
        //adds the Namespace Admin menu depending on the user role
        items.push(
            { label: <Divider className='.ant-menu-item' orientation="left"><h3>Namespace Management</h3></Divider>, key: 'sideNM' },
        );
    }
    if (isAuthenticated && roles.some((role: any) => ["nsadmin_packages"].includes(role))) {
        items.push(
            { label: <NavLink to="/nsadmin/packages">My Packages</NavLink>, key: '/nsadmin/packages', icon: <AppstoreOutlined /> },
        );
    }
    if (isAuthenticated && roles.some((role: any) => ['nsadmin_service_accounts'].includes(role))) {
        items.push(
            { label: <NavLink to="/nsadmin/service-accounts">My Service Accounts</NavLink>, key: '/nsadmin/service-accounts', icon: <ApiOutlined style={{ fontSize: "1.2em" }} /> },
        );
    }
    if (isAuthenticated && roles.some((role: any) => ["nsadmin_users"].includes(role))) {
        items.push(
            { label: <NavLink to="/nsadmin/users">My Users</NavLink>, key: '/nsadmin/users', icon: <UsergroupAddOutlined /> },
        );
    }



    //handles the collapse of the sidebar
    const handleCollapse = (value: boolean) => {
        setCollapsed(value);
        props.handleBarCollapse();
    }

    return (
        <Sider
            width={250}
            style={{
                overflow: "auto",
                height: 'calc(100vh - 75px)',
                position: "sticky",
                top: 75,
                left: 0
            }}
            theme="light"
            collapsible
            collapsed={collapsed}
            onCollapse={handleCollapse}
            className={collapsed ? "collapsed" : "extended"} >
            <Menu defaultSelectedKeys={[pathname]} selectedKeys={[pathname]} theme="light" mode="vertical" className="sideAdmin" items={items} />
        </Sider>
    )
}