import { useEffect, useState } from "react"

import { callBackEnd } from "../utils/backEndUtils";
import moment from 'moment';

export default function usePackageStat(pack: Package,volume:any) {

    const [stats, setStats] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);

    useEffect(() => {

        async function getPackageStat() {
            try {
                setLoading(true);
                if (pack === undefined || volume=== undefined) throw new Error('Package / Volume is undefined');

                const targetEnv: string = pack.environment;

                let fromDate: string = '';
                const toDate: string = moment().format('YYYY/MM/DD');
                switch (volume.cycle) {
                    case 'DAILY':
                        fromDate = moment().format('YYYY/MM/DD');
                        break;
                    case 'MONTHLY':
                        fromDate = moment().startOf('month').format('YYYY/MM/DD');
                        break;
                    default:
                        fromDate = moment(volume?.anniversary).format('YYYY/MM/DD');
                }
                let data: string = JSON.stringify({
                    fromDate,
                    toDate,
                    ...(volume !== null && { packageId: volume.uid })
                })

                const response: any = await callBackEnd(targetEnv, 'POST', 'api/v1/core/stats', data).catch((e: any) => { setError(e) });

                setStats(response);
            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }
        getPackageStat().catch((error) => { console.error(error) });
    }
        , [pack,volume])

    return { stats, loading, error }
}
