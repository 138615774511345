import { callBackEnd } from "../utils/backEndUtils";
import moment from "moment";

export default function useElasticStats(env: string, fromDate: string, toDate: string, client: string, project: string, product: string, pack: string) {
    //Build URL params for get
    const urlParams: string = Object.entries({
        fromDate: moment(Date.parse(fromDate)).format('YYYYMMDD'),
        toDate: moment(Date.parse(toDate)).format('YYYYMMDD'),
        ...(client !== '' && { client }),
        ...(project !== '' && { project }),
        ...(pack !== '' && { packageId: pack }),
        ...(product !== '' && { product: product.toLowerCase() })
    }).map(e => e.join('=')).join('&');

    const targetEnv: string = `${env}`;

    const topMatches = async (): Promise<any> => {
        const response: any = await callBackEnd(targetEnv,
            'GET',
            `api/v1/core/statistics/matches/top?${urlParams}`)
            .catch((e: any) => { throw new Error(e) });

        if (Array.isArray(response.data)) {
            console.log(response.data)
            return response.data;
        } else {
            throw new Error('Invalid response from API');
        }
    }
    const hitrates = async (): Promise<any> => {
        const response: any = await callBackEnd(targetEnv,
            'GET',
            `api/v1/core/statistics/requests/hitrates?${urlParams}`)
            .catch((e: any) => { throw new Error(e) });

        if (Array.isArray(response.data)) {
            return response.data;
        } else {
            throw new Error('Invalid response from API');
        }
    }
    const elapsed = async (): Promise<any> => {
        const response: any = await callBackEnd(targetEnv,
            'GET',
            `api/v1/core/statistics/requests/elapsed?${urlParams}`)
            .catch((e: any) => { throw new Error(e) });

        if (Array.isArray(response.data)) {
            return response.data;
        } else {
            throw new Error('Invalid response from API');
        }
    }
    const topCategories = async (): Promise<any> => {
        const response: any = await callBackEnd(targetEnv,
            'GET',
            `api/v1/core/statistics/matches/top/categories?${urlParams}`)
            .catch((e: any) => { throw new Error(e) });

        if (Array.isArray(response.data)) {
            return response.data;
        } else {
            throw new Error('Invalid response from API');
        }
    }
    const topSanctions = async (): Promise<any> => {
        const response: any = await callBackEnd(targetEnv,
            'GET',
            `api/v1/core/statistics/matches/top/sanctions?${urlParams}`)
            .catch((e: any) => { throw new Error(e) });

        if (Array.isArray(response.data)) {
            return response.data;
        } else {
            throw new Error('Invalid response from API');
        }
    }
    const scoreMatch = async (): Promise<any> => {
        const response: any = await callBackEnd(targetEnv,
            'GET',
            `api/v1/core/statistics/matches/scores?${urlParams}`)
            .catch((e: any) => { throw new Error(e) });

        if (Array.isArray(response.data)) {
            return response.data;
        } else {
            throw new Error('Invalid response from API');
        }
    }

    return {topMatches, hitrates, elapsed, topCategories, topSanctions, scoreMatch }

}