import { useEffect, useState } from "react";

import { callBackEnd } from "../utils/backEndUtils";

export default function useNamespaces() {

    const [namespaces, setNamespaces] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        async function getNamespaces() {
            try {
                setLoading(true);
                const response = await callBackEnd('portal', 'get', 'namespaces');

                if (Array.isArray(response)) {
                    setNamespaces(response);
                    setError(null);
                } else {
                    throw new Error('Invalid response from API');
                }

            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }
        getNamespaces();
    }, [])

    return { namespaces, loading, error }
}
