import { Card as AntdCard, CardProps } from 'antd';

import { ReactNode } from 'react';

type Props = { children: ReactNode } & CardProps;

export const WidgetCard = ({ children, ...others }: Props) => {
  return (
    <AntdCard className="card" {...others}>
      {children}
    </AntdCard>
  );
};