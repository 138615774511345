import { Button, Card, Form, Input, Select, Space, Table, Tag, Tooltip, Typography } from "antd";
import { CustomerServiceOutlined, EyeTwoTone } from "@ant-design/icons";

import { Content } from "antd/es/layout/layout";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import MotionBox from "../Layout/Animated/MotionBox";
import { SecureWithLogin } from "../../utils/secure";
import type { TableProps } from 'antd';
import { useForm } from "antd/es/form/Form";

interface DataType {
    key: string;
    case: string;
    tags: string[];
    status: string,
    reporter: string;
    creationDate: Date;
    lastUpdated: Date;
}

export default function SupportHome() {
    const [form] = useForm();

    const handleFormNewTicket = (values: any) => {
        console.log("🚀 ~ handleFormNewTicket ~ values:", values);

    }

    const columns: TableProps<DataType>['columns'] = [
        {
            title: 'Case',
            dataIndex: 'case',
            key: 'case',
            render: (text: string) => <Tooltip title={text}><Typography.Text style={{ width: "250px" }} ellipsis={true}>{text}</Typography.Text></Tooltip>,
        },
        {
            title: 'Tags',
            key: 'tags',
            dataIndex: 'tags',
            render: (_, { tags }) => (
                <>
                    {tags.map((tag) => {
                        let color = tag.length > 5 ? 'geekblue' : 'green';
                        if (tag === 'bug' || tag === 'incident') {
                            color = 'volcano';
                        }
                        else if (tag === 'request') {
                            color = 'green';
                        }
                        return (
                            <Tag color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: (status: string) => {
                let color = status === 'closed' ? 'volcano' : 'green';
                return (
                    <Tag color={color} key={status}>
                        {status.toUpperCase()}
                    </Tag>
                );
            },
        },
        {
            title: 'Reporter',
            dataIndex: 'reporter',
            key: 'reporter',
            render: (text: string) => <>{text}</>,
        },
        {
            title: 'Reported on',
            dataIndex: 'creationDate',
            key: 'creationDate',
            render: (text: Date) => <Moment format="YYYY/MM/DD h:mm:ss a">{text}</Moment>,
        },
        {
            title: 'Last Updated',
            dataIndex: 'lastUpdated',
            key: 'lastUpdated',
            render: (text: Date) => <Moment format="YYYY/MM/DD h:mm:ss a">{text}</Moment>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Link to={`/support/${record.key}`}>
                        <Button type="link">
                            <EyeTwoTone />
                        </Button>
                    </Link>
                </Space>
            ),
        },
    ];

    const data: DataType[] = [
        {
            key: '1',
            case: 'Issue with the login page',
            tags: ['question'],
            status: 'open',
            reporter: 'John Doe',
            lastUpdated: new Date(),
            creationDate: new Date()
        },
        {
            key: '2',
            case: "I'm having trouble with the model validation",
            tags: ['question'],
            status: 'open',
            reporter: 'Jane Doe',
            lastUpdated: new Date(),
            creationDate: new Date()
        },
        {
            key: '3',
            case: 'Add a new feature to the dashboard',
            tags: ['request'],
            status: 'closed',
            reporter: 'John Doe',
            lastUpdated: new Date(),
            creationDate: new Date()
        },
        {
            key: '4',
            case: "I'm having trouble loading the list of users in my Namespace. It's taking too long and I'm wondering why it's happening.",
            tags: ['bug'],
            status: 'closed',
            reporter: 'Jane Doe',
            lastUpdated: new Date(),
            creationDate: new Date()
        },
        {
            key: '5',
            case: "Api in the Documentation is not responding.",
            tags: ['incident'],
            status: 'closed',
            reporter: 'Jane Doe',
            lastUpdated: new Date(),
            creationDate: new Date()
        },
    ];

    return (
        <SecureWithLogin role={['portal_support']} callbackPath="/support">
            <MotionBox>
                <Content>
                    <Card title="My History" type="inner">
                        <Table
                            columns={columns}
                            dataSource={data}
                            rowKey="key"
                            scroll={{ x: 'max-content' }}
                            pagination={{
                                showSizeChanger: data.length > 10,
                                pageSizeOptions: ['10', '20', '30', '40', '50'],
                                defaultPageSize: 10
                            }}
                        />
                    </Card>
                </Content>
            </MotionBox>
            <MotionBox>
                <Content>

                    <Card
                        title="Contact support" type="inner">
                        <Form
                            labelWrap
                            form={form}
                            onFinish={handleFormNewTicket}
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 21 }}
                        >
                            <Form.Item
                                label="Type"
                                name="type"
                                rules={[{ required: true, message: 'Please select a type' }]}
                            >
                                <Select
                                    options={[
                                        { label: 'Question', value: 'question' },
                                        { label: 'Bug Report', value: 'bug' },
                                        { label: 'Incident', value: 'incident' },
                                        { label: 'Request', value: 'request' },
                                        { label: 'Other', value: 'other' }
                                    ]}
                                    allowClear={false}
                                />
                            </Form.Item>
                            <Form.Item
                                label='Describe your case'
                                name='issue'
                                rules={[{ required: true, message: 'Please fill in your case' }]}
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 3, span: 21 }}>
                                <Space size="middle">
                                    <Button type="text" onClick={() => form.resetFields()}>
                                        Reset
                                    </Button>
                                    <Button type="primary" htmlType="submit" icon={<CustomerServiceOutlined />}>
                                        Send case to support
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </Card>
                </Content>
            </MotionBox>
        </SecureWithLogin>
    )
}
