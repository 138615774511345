import { Alert, Button, Card, Form, Input, Radio, RadioChangeEvent, Select, Space } from "antd";
import { BulbOutlined, SaveOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";

import MotionBox from "../../../Layout/Animated/MotionBox";
import { SecureWithLogin } from "../../../../utils/secure";
import { callBackEnd } from "../../../../utils/backEndUtils";
import { useState } from "react";

export default function SetAuthentication() {
    const { namespaceId, serviceAccountId } = useParams();
    const navigate = useNavigate();


    const [currentAuthentication, setCurrentAuthentication] = useState<string>('SECRET');



    const handleForm = (values: any) => {

        async function saveAuth(data: ServiceAccountsCredentialsFormDataType) {
            try {
                await callBackEnd('portal', 'put', `namespaces/${namespaceId}/service-accounts/${serviceAccountId}/credentials`, JSON.stringify(data));
                navigate(`/nsadmin/service-accounts`);
            } catch (error: any) {
                console.log(error);
            }
        }


        if (namespaceId && namespaceId !== '' && serviceAccountId && serviceAccountId !== '') {

            const formData: ServiceAccountsCredentialsFormDataType = {
                type: values.authentication
            }

            if (currentAuthentication === 'SECRET') {

                formData.secret = values.secret ?? '';

            }
            else if (currentAuthentication === 'X509_CERTIFICATE') {
                formData.subjectDN = values.subjectDN ?? '';
                formData.regex = values.regex ?? false;
            }

            saveAuth(formData).catch((error) => { console.log(error) });
        }
    }

    return (
        // <SecureWithLogin callbackPath="/nsadmin" role={['nsadmin_service_accounts_credentials']}>
        <SecureWithLogin callbackPath="/nsadmin" role={['nsadmin_service_account']}>
            <MotionBox>
                <Card title="Service Account credentials" extra={<Alert message={<><BulbOutlined />{' '}Please save your authentication method, as it won't be provided later</>} type='warning' />}>
                    <Form
                    labelWrap
                        onFinish={handleForm}
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 21 }}
                    >
                        <Form.Item
                            label='Authentication type'
                            name='authentication'
                            rules={[{ required: true, message: 'Please set your authentication type' }]}
                            initialValue={'SECRET'}
                        >                            
                            <Radio.Group
                                options={[
                                    { label: 'Secret', value: 'SECRET' },
                                    { label: 'X509_CERTIFICATE', value: 'X509_CERTIFICATE' },
                                ]}
                                defaultValue={"secret"}
                                onChange={({ target: { value } }: RadioChangeEvent) => {
                                    setCurrentAuthentication(value);
                                }}
                                optionType="button"
                                buttonStyle="solid"
                            />
                        </Form.Item>
                        {currentAuthentication === 'SECRET' ?
                            <>
                                <Form.Item
                                    label="Secret"
                                    name="secret"
                                    rules={[{ required: true, message: 'Please input your secret' }]}
                                    hasFeedback
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item
                                    label="Confirm secret"
                                    name="secret2"
                                    dependencies={['createServiceAccount_secret']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your secret!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('secret') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The new secret that you entered do not match!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </>
                            :
                            <>
                                <Form.Item
                                    label="SubjectDN"
                                    name="subjectDN"
                                    rules={[{ required: true, message: 'Please input your SubjectDN' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label='Regex'
                                    name='regex'
                                    initialValue={'true'}
                                    rules={[{ required: true, message: 'Please set your Regex' }]}
                                >
                                    <Select
                                        allowClear={false}
                                    >
                                        <Select.Option value={true}>Yes</Select.Option>
                                        <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                            </>
                        }
                        <Form.Item wrapperCol={{ offset: 3, span: 21 }}>
                            <Space size="middle">
                                <Button type="text" onClick={() => navigate(`/nsadmin/service-accounts`)}>
                                    Cancel
                                </Button>
                                <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                                    Save
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Card>
            </MotionBox>
        </SecureWithLogin >
    )
}
