import { Button, Card, Form, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Content } from "antd/es/layout/layout";
import LoadingSkeleton from "../../Layout/LoadingSkeleton";
import MotionBox from "../../Layout/Animated/MotionBox";
import { SaveOutlined } from '@ant-design/icons';
import { SecureWithLogin } from "../../../utils/secure";
import { callBackEnd } from "../../../utils/backEndUtils";
import { useForm } from "antd/es/form/Form";

export default function Upgradenamespace() {

    const [namespaceStaticTypes, setNamespaceStaticTypes] = useState<NamespaceType[]>([]);
    const [form] = useForm();
    const navigate = useNavigate();
    const { namespaceId } = useParams();
    const [isProcessing,setIsProcessing] = useState<boolean>(false);


    useEffect(() => {
        async function getNamespaceStaticTypes() {
            try {
                const response: any = await callBackEnd('portal', 'get', `static/namespace_types`);
                if (Array.isArray(response)) {
                    console.log("🚀 ~ getNamespaceStaticTypes ~ response:", response);
                    setNamespaceStaticTypes(response);
                }
            }
            catch (error: any) {
                console.log(error);
            }
        }


        async function getNamespace() {
            try {
                const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}`);
                if (response?.type) {
                    form.setFieldsValue({
                        type: response.type,
                    });
                }

            } catch (error: any) {
                console.log(error);
            }
        }

        getNamespace().catch((e: any) => console.log(e));
        getNamespaceStaticTypes().catch((e: any) => console.log(e));
    }, [form,namespaceId]);
    
    const handleForm = (values: any) => {
        console.log("🚀 ~ handleForm ~ values:", values);
        
        async function upgrade(nsType: NamespaceType) {
            try {
                setIsProcessing(true);
                await callBackEnd('portal', 'PATCH', `namespaces/${namespaceId}/type/${nsType}`).catch((e: any) => console.log(e.status));

            } catch (error: any) {
                console.log(error);
            }
            finally{
                setIsProcessing(false);
                navigate(`/namespaces/${namespaceId}`);
            }
        }
        if(values.type && namespaceId){
            upgrade(values.type).catch((e: any) => console.log(e));
        }
    }

    return (
        <SecureWithLogin callbackPath="/namespaces" role={['crm_namespaces']}>
            <>
                {namespaceStaticTypes.length > 0 ?
                    <MotionBox>
                        <Content>
                            <Card
                                title="Upgrade Namepace"
                            >
                                <Form
                                    labelWrap
                                    form={form}
                                    onFinish={handleForm}
                                    labelCol={{ span: 3 }}
                                    wrapperCol={{ span: 10 }}
                                >
                                    <Form.Item
                                        label="Type"
                                        name="type"
                                        rules={[{ required: true, message: 'Please set the Namespace type' }]}
                                    >
                                        <Select
                                            options={namespaceStaticTypes.map((nst: string) => {
                                                return { label: nst, value: nst }
                                            })}
                                        />
                                    </Form.Item>
                                    <Form.Item wrapperCol={{ offset: 3, span: 21 }}>

                                        <Space size="middle">
                                            <Button type="text" onClick={() => navigate(`/namespaces/${namespaceId}`)}>
                                                Cancel
                                            </Button>
                                            <Button type="primary" htmlType="submit" icon={<SaveOutlined />} loading={isProcessing}>
                                                Save
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </Content>
                    </MotionBox>
                    :
                    <LoadingSkeleton />
                }
            </>
        </SecureWithLogin>
    )
}