import { HomeOutlined } from '@ant-design/icons';
import LoadingSkeleton from "../../Layout/LoadingSkeleton";
import MotionBox from "../../Layout/Animated/MotionBox";
import { PageHeader } from "../../Layout/header/PageHeader";
import { SecureWithLogin } from '../../../utils/secure';
import UsersListUsersListWithNamespace from './UsersListWithNamespace';
import useNamespaces from '../../../hooks/useNamespaces';
import useUsers from "../../../hooks/useUsers";

// import useUsersPaginated from '../../../hooks/useUsersPaginated';

export default function Users() {

    const { users, loading: usersLoading, error: usersError }: { users: UserProfile[], loading: boolean, error: any } = useUsers();
    // const { users:userPaginated, loading: usersPaginatedLoading, error: usersPaginatedError }: { users: UserProfile[], loading: boolean, error: any } = useUsersPaginated();
    // console.log("🚀 ~ file: Users.tsx:17 ~ Users ~ userPaginated:", userPaginated);

    const { namespaces, loading: loadingNamespaces, error: errorNamespaces }: { namespaces: Namespace[], loading: boolean, error: any } = useNamespaces();

    console.log(namespaces, loadingNamespaces, errorNamespaces, users, usersLoading, usersError)


    return (
        <SecureWithLogin callbackPath="/users" role={['crm_users']}>
            {(!usersLoading && usersError === null && users.length > 0 && !loadingNamespaces && errorNamespaces === null && namespaces.length > 0) ?
                <MotionBox>
                    <PageHeader
                        title='All Users'
                        subtitle='Access and Enablement'
                        breadcrumbs={[
                            {title:<span><HomeOutlined/>&nbsp;Home</span>,href: '/',},
                            {title:<span>All users</span>}]}
                    />
                    <UsersListUsersListWithNamespace
                        title='All Users'
                        namespaces={namespaces}
                        users={users}
                        />
                </MotionBox>
                :
                <LoadingSkeleton />
            }
        </SecureWithLogin>
    )
}
