import { useEffect, useState } from "react";

import { callBackEnd } from "../utils/backEndUtils";

export default function useNews(env: string,newsId: string = '') {
    const [news, setNews] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);

    const targetEnv: string = `${env}`;

    useEffect(() => {
        async function getNews() {
            try {
                setLoading(true);
                let content: any;
                let response: any;
                if (newsId === ''){
                    content = await callBackEnd('directus', 'get', `items/news?sort[]=-dateStart`);
                    response = content.data.filter((item: any) => item.status === "published");
                }
                else{
                    content = await callBackEnd('directus', 'get', `items/news/${newsId}`);
                    content.data.status === 'published' ? response = content.data : response = null;
                }
                

                setNews(response);
                setError(null);
            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }
        getNews().catch((e: any) => { console.log(e) });

    }, [targetEnv, newsId]);

    return { news, loading, error };
}