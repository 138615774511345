import { Button, Card, Col, Collapse, DatePicker, Divider, Form, Input, Radio, Row, Select, Slider, Space, Tabs, TabsProps, Upload } from 'antd'
import { useContext, useState } from 'react'

import { DefaultOptionType } from 'antd/es/select';
import { EnvContext } from '../../contexts/EnvironmentContext'
import LoadingSkeleton from '../Layout/LoadingSkeleton'
import MotionBox from '../Layout/Animated/MotionBox'
import ReactJson from '@microlink/react-json-view';
import { SecureWithLogin } from '../../utils/secure'
import TryItResultsStats from './stats/TryItResultsStats';
import TryItResultsTable from './tables/TryItResultsTable';
import { UploadOutlined } from '@ant-design/icons';
import { callBackEnd } from '../../utils/backEndUtils'
import dayjs from 'dayjs';
import { getCountryListAlpha2 } from '../../utils/geoInfos';
import { usePapaParse } from 'react-papaparse';

export default function TryItForm() {
    const env: string = useContext(EnvContext);
    const { readString } = usePapaParse();
    const [form] = Form.useForm();

    const [isFormSubmitable, setIsFormSubmitable] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [dobType, setDobType] = useState<string>('full');
    const [showCityField, setShowCityField] = useState<boolean>(false);
    const [inputMethod, setInputMethod] = useState<string>('single');
    const [isMetaMatching, setIsMetaMatching] = useState<boolean>(true);
    //////////////////////////////////////

    const [requestCode, setRequestCode] = useState<any>({
        options: {},
        records: []
    });
    const [responseCode, setResponseCode] = useState<any>();

    const buildRequestCode = async (values: any) => {

        console.log(values.optionCityPolicyLevel)
        //Build the request code
        const rcCopy: any = { ...requestCode };

        //Options
        rcCopy.options = {
            threshold: values?.optionThreshold,
            metaScore: values?.optionHolisticMatching === "metaScore",
            dobPrecision: values?.optionDobPrecision,
            cityProximity: values?.optionCityProximity,
        }

        if (values?.optionHolisticMatching === "metaScore") {
            rcCopy.options.dobPolicy = {
                value: "optional",
                level: values?.optionDobPolicyLevel
            }
            rcCopy.options.genderPolicy = {
                value: "optional",
                level: values?.optionGenderPolicyLevel
            };
            rcCopy.options.countryPolicy = {
                value: "optional",
                level: values?.optionCountryPolicyLevel
            };
            rcCopy.options.cityPolicy = {
                value: "optional",
                level: values?.optionCityPolicyLevel
            }
        }
        else{
            rcCopy.options.dobPolicy = {
                value: "required",
            }
            rcCopy.options.genderPolicy = {
                value: "required",
            };
            rcCopy.options.countryPolicy = {
                value: "required",
            };
            rcCopy.options.cityPolicy = {
                value: "required",
            }

        }



        //records
        rcCopy.records = [];

        if (inputMethod === 'single') {


            rcCopy.records.push({
                ...(values?.name && { object: values?.name?.trim() }),
            });


            //add metadata if exists
            if (values.metadataGender || values.metadataDob || values.metadataPlacesCountry || values.metadataPlacesCity) {
                rcCopy.records[0].metadata = {};

                if (values.metadataDob) {
                    //format the date of birth if exists
                    let formattedDataOfBirth: string = '';
                    if (values.metadataDobType === 'full') {
                        formattedDataOfBirth = dayjs(values.metadataDob).format('YYYY-MM-DD');
                    }
                    else {
                        formattedDataOfBirth = dayjs(values.metadataDob).format('YYYY');
                    }

                    rcCopy.records[0].metadata.dobs = [formattedDataOfBirth];
                }
                if (values.metadataGender) {
                    rcCopy.records[0].metadata.gender = values.metadataGender;
                }
                if (values.metadataPlacesCountry) {
                    rcCopy.records[0].metadata.places = [{
                        country: values.metadataPlacesCountry,
                        ...(values.metadataPlacesCity && { city: values.metadataPlacesCity })
                    }];
                }
            }
        } else if (values.inputMethod === 'csv' && values?.csvFile?.fileList.length > 0) {
            const csvFile: any = values?.csvFile?.fileList[0].originFileObj;
            rcCopy.records = await returnCsvData(csvFile);
        }
        return rcCopy;
    }

    const returnCsvData = async (file: any) => {
        return new Promise((resolve, reject) => {
            readString(file, {
                header: true,
                comments: '#',
                complete: (results: any) => {
                    const sanitizedData: any = results.data
                        .filter((r: any) => r.name !== '')
                        .map((record: any) => {
                            let toReturn: any = {
                                object: record.name,
                            }
                            record.gender = record.gender?.toLowerCase();
                            record.country = record.country?.toUpperCase();

                            const isValidGender: boolean = (record.gender !== '' && (record.gender === 'male' || record.gender === 'female' || record.gender === 'other'));
                            const isValidCountry: boolean = (record.country !== '' && getCountryListAlpha2().map((c: any) => c.value).includes(record.country));
                            const isValidCity: boolean = (record.city !== '' && isValidCountry);
                            const isValidDob: boolean = (record.dateOfBirth !== '' && (dayjs(record.dateOfBirth, 'YYYY-MM-DD', true).isValid() || dayjs(record.dateOfBirth, 'YYYY', true).isValid()));

                            if (isValidGender || isValidCountry || isValidCity || isValidDob) {
                                toReturn.metadata = {
                                    ...(isValidGender && { gender: record.gender }),
                                    ...(isValidDob && { dobs: [record.dateOfBirth] }),
                                    ...(isValidCity || isValidCountry) && {
                                        places: [
                                            {
                                                ...(isValidCountry && { country: record.country }),
                                                ...(isValidCity && { city: record.city })
                                            }
                                        ]
                                    }
                                }
                            }

                            return toReturn;
                        });
                    resolve(sanitizedData);
                },
                error: (error: Error) => {
                    reject(error);
                }
            });
        });
    }

    const handleForm = async (values: any) => {
        setLoading(true);

        const rc: any = await buildRequestCode(values);
        setRequestCode(rc);

        //Call the backend
        const targetEnv: string = (env === 'DEV') ? "DEV" : "SANDBOX";
        callBackEnd(targetEnv, 'POST', `api/v1/jetscan/screen/sanctions`, JSON.stringify(rc))
            .then((response: any) => {

                setResponseCode(response);
                setLoading(false);
            })
            .catch((error: any) => {
                console.log(error)
            })
    }

    const resetForm = () => {
        form.resetFields();
        setRequestCode({
            options: {},
            records: []
        });
        setResponseCode(undefined);
        setIsFormSubmitable(false);
        setDobType('full');
        setShowCityField(false);
        setLoading(false);
        setInputMethod('single');
        setIsMetaMatching(true);
    }
    const handleFieldChange = (changedFields: any, allFields: any) => {
        if (changedFields[0]?.name[0] === 'name') {
            if (changedFields[0]?.value?.length) {
                setIsFormSubmitable(true);
            }
            else {
                setIsFormSubmitable(false);
            }
        }
        if (changedFields[0]?.name[0] === 'metadataDobType') {
            setDobType(changedFields[0]?.value);
        }
        if (changedFields[0]?.name[0] === 'metadataPlacesCountry') {
            if (changedFields[0]?.value?.length) {
                setShowCityField(true);
            }
            else {
                setShowCityField(false);
            }
        }
        if (changedFields[0]?.name[0] === 'inputMethod') {
            setInputMethod(changedFields[0]?.value);
        }
        if (changedFields[0]?.name[0] === 'csvFile') {
            setIsFormSubmitable(true);
        }
        if (changedFields[0]?.name[0] === 'optionHolisticMatching') {
            setIsMetaMatching(changedFields[0]?.value === "metaMaching");
            if(changedFields[0]?.value === "metaMaching"){
                form.setFieldsValue({
                    optionDobPolicyValue: 'required',
                    optionGenderPolicyValue: 'required',
                    optionCountryPolicyValue: 'required',
                    optionCityPolicyValue: 'required',
                });
            }
            else{
                form.setFieldsValue({
                    optionDobPolicyValue: 'optional',
                    optionGenderPolicyValue: 'optional',
                    optionCountryPolicyValue: 'optional',
                    optionCityPolicyValue: 'optional',
                });
            }
        }
    }

    const handleValuesChange = async (changedValues: any, allValues: any) => {
        const request: any = await buildRequestCode(allValues);
        setRequestCode(request);
    }

    const tabs: TabsProps['items'] = [
        {
            key: '1',
            label: 'Date of birth policy',
            forceRender: true,
            children: <>
                <Form.Item
                    label="Date of birth policy"
                    layout='horizontal'
                >
                    <Form.Item
                        label="Match"
                        name='optionDobPolicyValue'
                        tooltip="Tells if the metadata value SHOULD be defined in the watch list record in order to accept a hit."
                        initialValue={isMetaMatching ? "required" : "optional"}
                        wrapperCol={{ span: 10 }}
                    >
                        <Radio.Group disabled>
                            <Radio value="optional">Optional</Radio>
                            <Radio value="required">Required</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label="Level"
                        name='optionDobPolicyLevel'
                        tooltip="If the metadata value is found and matched, tells the level of importance you give the hit."
                        initialValue={"medium"}
                        wrapperCol={{ span: 10 }}
                        hidden={isMetaMatching}
                    >
                        <Radio.Group>
                            <Radio value="low">Low</Radio>
                            <Radio value="medium">Medium</Radio>
                            <Radio value="high">High</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    label="Dob Precision"
                    tooltip="In case of year matching, the accepted difference (in years)"
                    name='optionDobPrecision'
                    initialValue={0}
                    wrapperCol={{ span: 12 }}
                >
                    <Slider
                        min={0}
                        max={10}
                        marks={{
                            0: '0',
                            2: '2 years',
                            4: '4 years',
                            6: '6 years',
                            8: '8 years',
                            10: '10 years'
                        }}
                    />
                </Form.Item>
            </>,
        },
        {
            key: '2',
            label: 'Gender policy',
            forceRender: true,
            children: 
                <Form.Item
                    label="Gender policy"
                    layout='horizontal'
                >
                    <Form.Item
                        label="Match"
                        name='optionGenderPolicyValue'
                        tooltip="Tells if the metadata value SHOULD be defined in the watch list record in order to accept a hit."
                        initialValue={isMetaMatching ? "required" : "optional"}
                        wrapperCol={{ span: 10 }}
                    >
                        <Radio.Group disabled>
                            <Radio value="optional">Optional</Radio>
                            <Radio value="required">Required</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Level"
                        name='optionGenderPolicyLevel'
                        tooltip="If the metadata value is found and matched, tells the level of importance you give the hit."
                        initialValue={"medium"}
                        wrapperCol={{ span: 10 }}
                        hidden={isMetaMatching}
                    >
                        <Radio.Group>
                            <Radio value="low">Low</Radio>
                            <Radio value="medium">Medium</Radio>
                            <Radio value="high">High</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form.Item>
        },
        {
            key: '3',
            label: 'Country Policy',
            forceRender: true,
            children: 
                <Form.Item
                    label="Country policy"
                    layout='horizontal'
                >
                    <Form.Item
                        label="Match"
                        name='optionCountryPolicyValue'
                        tooltip="Tells if the metadata value SHOULD be defined in the watch list record in order to accept a hit."
                        initialValue={isMetaMatching ? "required" : "optional"}
                        wrapperCol={{ span: 10 }}
                    >
                        <Radio.Group disabled>
                            <Radio value="optional">Optional</Radio>
                            <Radio value="required">Required</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Level"
                        name='optionCountryPolicyLevel'
                        tooltip="If the metadata value is found and matched, tells the level of importance you give the hit."
                        initialValue={"medium"}
                        wrapperCol={{ span: 10 }}
                        hidden={isMetaMatching}
                    >
                        <Radio.Group disabled>
                            <Radio value="low">Low</Radio>
                            <Radio value="medium">Medium</Radio>
                            <Radio value="high">High</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form.Item>
        },
        {
            key: '4',
            label: 'City policy',
            forceRender: true,
            disabled: !showCityField,
            children: <>
                <Form.Item
                    label="City policy"
                    layout='horizontal'
                >
                    <Form.Item
                        label="Match"
                        name='optionCityPolicyValue'
                        tooltip="Tells if the metadata value SHOULD be defined in the watch list record in order to accept a hit."
                        initialValue={isMetaMatching?"required":"optional"}
                        wrapperCol={{ span: 10 }}
                    >
                        <Radio.Group disabled>
                            <Radio value="optional">Optional</Radio>
                            <Radio value="required">Required</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Level"
                        name='optionCityPolicyLevel'
                        tooltip="If the metadata value is found and matched, tells the level of importance you give the hit."
                        initialValue={"medium"}
                        wrapperCol={{ span: 10 }}
                        hidden={isMetaMatching}
                    >
                        <Radio.Group>
                            <Radio value="low">Low</Radio>
                            <Radio value="medium">Medium</Radio>
                            <Radio value="high">High</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    label="City Proximity"
                    name='optionCityProximity'
                    tooltip="Accepted distance (km) between two mismatched cities."
                    initialValue={50}
                    wrapperCol={{ span: 12 }}
                >
                    <Slider
                        min={0}
                        max={500}
                        marks={{
                            0: '0 km',
                            100: '100 km',
                            200: '200 km',
                            300: '300 km',
                            400: '400 km',
                            500: '500 km',
                        }}
                    />
                </Form.Item>
            </>
        },
    ];

    return (
        <SecureWithLogin role={['portal_doc', 'js_screen']} callbackPath="/tryIt">
            <MotionBox>
                <Row gutter={[15, 15]}>
                    <Divider orientation='left'>Screening Profile</Divider>
                    <Col xl={16} lg={24}>
                        <Form
                            labelWrap
                            form={form}
                            onFinish={handleForm}
                            onValuesChange={handleValuesChange}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 18 }}
                            onFieldsChange={handleFieldChange}
                        >

                            <Form.Item
                                label="Input method"
                                name='inputMethod'
                                initialValue='single'
                            >
                                <Radio.Group>
                                    <Radio value='single'>Single</Radio>
                                    <Radio value='csv'>Upload a CSV file</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {inputMethod === 'single' ?
                                <>
                                    <Form.Item
                                        label="Name to screen"
                                        tooltip="Please enter a first name and a last name as entering just one will return too many results"
                                        name='name'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter a first name and a last name',
                                            }
                                        ]}
                                    >
                                        <Input placeholder="Enter a name or a list of names separated by commas" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Gender"
                                        name="metadataGender"
                                    >
                                        <Select allowClear options={[
                                            { label: "Male", value: "male" },
                                            { label: "Female", value: "female" },
                                            { label: "Other", value: "other" }
                                        ]} />
                                    </Form.Item>
                                    <Form.Item label="Date of birth" style={{ marginBottom: 0 }}>
                                        <Form.Item
                                            label="Type"
                                            name='metadataDobType'
                                            initialValue={'full'}
                                            style={{ display: 'inline-block', marginRight: '40px' }}
                                        >
                                            <Radio.Group>
                                                <Radio value="full">Full date</Radio>
                                                <Radio value="year">Year only</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item
                                            label="Date"
                                            name='metadataDob'
                                            style={{ display: 'inline-block' }}
                                        >
                                            {dobType === 'full' ?
                                                <DatePicker />
                                                :
                                                <DatePicker picker="year" />
                                            }
                                        </Form.Item>
                                    </Form.Item>
                                    <Form.Item
                                        label="Country"
                                        name='metadataPlacesCountry'
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            placeholder="Select a country"
                                            optionFilterProp="label"
                                            options={getCountryListAlpha2() as DefaultOptionType[]} />
                                    </Form.Item>
                                    {showCityField &&
                                        <Form.Item
                                            label="City"
                                            tooltip="City will be ignored if a country isn't set"
                                            name='metadataPlacesCity'
                                        >
                                            <Input placeholder="Enter the city" />
                                        </Form.Item>
                                    }
                                </>
                                :
                                <Form.Item
                                    label="Upload a CSV file"
                                    name='csvFile'
                                    help={<p>
                                        <br />
                                        To ensure the best results, please follow these rules:
                                        <br />
                                        <br />
                                        <ul>
                                            <li>- There must be a name otherwise the whole line is ignored.</li>
                                            <li>- The gender must be equal to “male”, “female”,other” otherwise it is ignored [Not case sensitive] </li>
                                            <li>- The date of birth must be formatted as follows (YYYY-MM-DD or YYYY) otherwise it is ignored.</li>
                                            <li>- The country must be formatted as ISO Alpha2 (e.g. BE, FR, DE, UK, US, GB, ... ) otherwise it is ignored. [Not case sensitive] </li>
                                            <li>- The city must be set and country must be valid otherwise it is ignored</li>
                                        </ul>
                                        <br />
                                        Download the CSV template file <a href="/neterium-try-the-api-template.csv" download>here</a>
                                        <br />
                                        <br />
                                    </p>}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please upload a CSV file',
                                        }
                                    ]}
                                >
                                    <Upload maxCount={1} beforeUpload={(file: any) => {
                                        return false;
                                    }}>
                                        <Button icon={<UploadOutlined />}>Select File</Button>
                                    </Upload>
                                </Form.Item>
                            }


                            <Divider orientation="left">Screening Configuration</Divider>

                            <Form.Item
                                label="Holistic Matching"
                                name='optionHolisticMatching'
                                initialValue={"metaMaching"}
                                wrapperCol={{ span: 10 }}
                            >
                                <Radio.Group optionType="button" buttonStyle="solid">
                                    <Radio value="metaMaching">Meta Matching</Radio>
                                    <Radio value="metaScore">Meta Scoring</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Divider />
                            <Row>
                                <Col md={{ span: 18, offset: 6 }} sm={{ span: 24, offset: 0 }}>

                                    <Tabs defaultActiveKey="1" items={tabs} />
                                </Col>
                            </Row>

                            <Divider />
                            
                            <Form.Item
                                label="Threshold"
                                name='optionThreshold'
                                tooltip="Name matching threshold. When the threshold value is between 50 and 84 matching is more broad, and some hits returned in this mode, are not retrieved otherwise"
                                initialValue={85}
                                wrapperCol={{ span: 8 }}
                            >
                                <Slider 
                                min={85}
                                max={100} 
                                    marks={{
                                        85: '85',
                                        90: '90',
                                        95: '95',
                                        100: '100'
                                    }}
                                />
                            </Form.Item>

                            {isFormSubmitable &&
                                <Form.Item wrapperCol={{ offset: 3, span: 21 }}>
                                    <Space size="middle">
                                        <Button type="default" onClick={resetForm}>
                                            Reset
                                        </Button>
                                        <Button type="primary" htmlType="submit" loading={loading}>
                                            Send Request
                                        </Button>
                                    </Space>
                                </Form.Item>
                            }
                        </Form>
                    </Col>

                    <Col xl={8} lg={24}>
                        <MotionBox>
                            <Card
                                title={"API Request"}
                                type="inner"
                            >
                                <ReactJson src={requestCode} indentWidth={2} />
                            </Card>
                        </MotionBox>
                    </Col>
                </Row>

                {(responseCode && !loading) &&
                    <TryItResultsStats responseCode={responseCode} />
                }
                <Row gutter={[24, 24]}>
                    {(responseCode && !loading) &&
                        <>
                            {responseCode.results.map((results: any, index: number) => {
                                return (
                                    <Col key={index} md={24} sm={24}>
                                        <Divider orientation='left'>API Matches found for {results.object}</Divider>
                                        <TryItResultsTable matches={results.matches} />
                                    </Col>
                                )
                            })}
                        </>
                    }

                    {(responseCode && !loading) &&
                        <Col md={24} sm={24}>
                            <Collapse
                                items={[{ key: '1', label: 'JSON API Response', children: <ReactJson src={responseCode} indentWidth={2} /> }]}
                            />
                        </Col>
                    }
                    {loading &&
                        <Col md={24} sm={24}>
                            <LoadingSkeleton />
                        </Col>
                    }
                </Row>

            </MotionBox>
        </SecureWithLogin >
    )
}
