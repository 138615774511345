import { createContext, useCallback, useContext, useState } from 'react'

export const EnvContext = createContext<EnvType>("SANDBOX");
export const EnvUpdateContext = createContext<((environment: EnvType) => void) | undefined>(undefined);

export function EnvironmentContextProvider({ children }: any) {

    const savedValue: string | null = localStorage.getItem("portal-prefered-env") ?? 'SANDBOX';

    const [environment, setEnvironment] = useState<EnvType>(savedValue as EnvType);

    const updateEnvironment = useCallback((environment: EnvType) => {
        setEnvironment(environment);
        localStorage.setItem("portal-prefered-env", environment);
    }, []);

    return (
        <EnvContext.Provider value={environment}>
            <EnvUpdateContext.Provider value={updateEnvironment}>
                {children}
            </EnvUpdateContext.Provider>
        </EnvContext.Provider>
    )
}

export function useEnvironment() {
    return useContext(EnvContext);
}

export function useUpdateEnvironment() {
    const updateEnvironment = useContext(EnvUpdateContext);
    if (!updateEnvironment) {
        throw new Error('useUpdateEnvironment must be used within an EnvironmentContextProvider');
    }
    return updateEnvironment;
}