import { Table, Tooltip } from 'antd';
import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import LoadingSkeleton from '../Layout/LoadingSkeleton';

export default function TopMatches(props: { topMatches: Promise<any> }) {
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await props.topMatches;
                setData(result);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [props.topMatches]);

    const columns = [
        {
            title: 'Profile Name',
            dataIndex: 'profileName',
            key: 'profileName',
            sorter: (a: any, b: any) => a.profileName.localeCompare(b.profileName),
        },
        {
            title: 'Profile ID',
            dataIndex: 'profileId',
            key: 'profileId',
            render: (text: any, record: any) => <Tooltip title="Open profile in a new tab"><Link to={`/profileDetails/${record.profileId}`} target='_blank'>{record.profileId}</Link></Tooltip>,
            sorter: (a: any, b: any) => a.profileId.localeCompare(b.profileId),
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            sorter: (a: any, b: any) => a.count - b.count,
            defaultSortOrder: 'descend' as const
        },
    ];

    return loading ? <LoadingSkeleton /> : <Table dataSource={data} columns={columns} pagination={false} scroll={{ x: 'max-content' }} />;
}