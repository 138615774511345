import { Button, Result, Space } from 'antd';
import { FC, PropsWithChildren, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useOidcAccessToken as getAccessToken, useOidc as getOidc, useOidcUser as getUser } from '@axa-fr/react-oidc';

import { EnvContext } from '../contexts/EnvironmentContext';

// import LoginEmail from '../components/login/LoginEmail';

const isInStringArray = (stringToFind: string[], stringCollection: string[]): boolean => {
    for (let str of stringToFind) {
        if (!stringCollection.includes(str)) {
            return false;
        }
    }
    return true;
}

const getTokenRoles = (accessTokenPayload: any, env: string): string[] => {

    let tokenRoles: string[] = [];

    if (accessTokenPayload?.resource_access?.portal?.roles) {
        tokenRoles = [...tokenRoles, ...accessTokenPayload.resource_access.portal.roles];
    }
    if (accessTokenPayload?.resource_access?.[env.toLowerCase()]?.roles) {

        tokenRoles = [...tokenRoles, ...accessTokenPayload.resource_access[env.toLowerCase()].roles];
    }
    // tokenRoles = tokenRoles.filter(role => role !== 'crm_packages');

    // console.table(tokenRoles)
    return tokenRoles;
}

export type SecureProps = {
    callbackPath?: string;
    role?: string[]
};

export type AccessProps = {
    role?: string[];
    authenticated?: boolean;
};

export type OwnEditsProps = {
    userId: string;
};



//Used to secure access to components depending on the user's role
export const SecureWithLogin: FC<PropsWithChildren<SecureProps>> = ({ children, callbackPath = null, role = null }) => {
    const { isAuthenticated } = getOidc('config');
    const { accessTokenPayload } = getAccessToken('config');
    const env: string = useContext(EnvContext);
    const navigate = useNavigate();

    if (!isAuthenticated) {
        // return <LoginEmail path={callbackPath} />;
        navigate('/');
    }

    if (role !== null && accessTokenPayload?.resource_access !== undefined) {
        const tokenRoles: string[] = getTokenRoles(accessTokenPayload, env);

        if (isInStringArray(role, tokenRoles)) {
            return <>{children}</>;
        }
    }
    return <Result
        status={"403"}
        title="Access Denied"
        subTitle="You do not have access to this module"
        extra={
            <Link to="/"><Button type="primary">Go back to home page</Button></Link>
        }
    />
};

//Made for the sidebar, to show a link if the user has access to it or not
export const ShowIfAccess: FC<PropsWithChildren<AccessProps>> = ({ children, role = null, authenticated = true }) => {
    const { isAuthenticated } = getOidc('config');
    const { accessTokenPayload } = getAccessToken('config');
    const env: string = useContext(EnvContext);

    if (!authenticated && !isAuthenticated) {
        return <>{children}</>;
    }

    if (authenticated && isAuthenticated && role === null) {
        return <>{children}</>;
    }
    if (authenticated && isAuthenticated && role !== null && accessTokenPayload?.resource_access?.portal?.roles !== undefined) {

        const tokenRoles: string[] = getTokenRoles(accessTokenPayload, env);
        if (isInStringArray(role, tokenRoles)) {
            return <>{children}</>;
        }
    }

    return <></>;
};

export const SecureOwnEdits: FC<PropsWithChildren<OwnEditsProps>> = ({ children, userId }) => {
    const { isAuthenticated } = getOidc('config');
    const navigate = useNavigate();

    const { oidcUser }: { oidcUser: any } = useOidcUser();
    const isRestricted: boolean = oidcUser?.jetUserId === userId; //user can't edit himself


    if (!isAuthenticated) {
        navigate('/');
    }

    if (!isRestricted) {
        return <>{children}</>;
    }

    return <Result
        status={"403"}
        title="Access Denied"
        subTitle="You can not edit your own account"
        extra={
            <Space size="middle">
                <Button type="primary" onClick={() => navigate(-1)}>Go back</Button>
                <Link to="/">
                    <Button type="primary">Go back to homepage</Button>
                </Link>
            </Space>
        }
    />


}


//Made for the sidebar, to show a link if the user has access to it or show it as disabled
export const ToggleIfAccess: React.FC<ToggleIfAccessProps> = ({ role, authenticated, contentPassed, contentFailed }) => {

    const { isAuthenticated } = getOidc('config');
    const { accessTokenPayload } = getAccessToken('config');
    const env: string = useContext(EnvContext);


    if (!authenticated && !isAuthenticated) {
        return contentPassed;
    }

    if (authenticated && isAuthenticated && role === null) {
        return contentPassed;
    }
    if (authenticated && isAuthenticated && role !== null && accessTokenPayload?.resource_access?.portal?.roles !== undefined) {

        const tokenRoles: string[] = getTokenRoles(accessTokenPayload, env);

        if (isInStringArray(role, tokenRoles)) {
            return contentPassed;
        }
    }

    return contentFailed;
};

export const useAuthInfo = () => {
    const { isAuthenticated } = getOidc('config');
    const { accessTokenPayload, accessToken } = getAccessToken('config');
    const env: string = useContext(EnvContext);

    const roles = accessTokenPayload ? getTokenRoles(accessTokenPayload, env) : [];

    return { isAuthenticated, roles, accessToken, accessTokenPayload };
};

export const useOidcAccessToken = () => {
    return getAccessToken('config');
}

export const useOidcUser = () => {
    return getUser('config')
}

export const useOidc = () => {
    return getOidc('config')
}

export const getProductsAccess = (accessTokenPayload: any, env: string): string[] => {
    const jetProducts: string[] = [];
    let resourceAccess = accessTokenPayload?.accessTokenPayload.resource_access;
    if (resourceAccess[env.toLocaleLowerCase()]?.roles?.includes('js_jetscan')) {
        jetProducts.push('jetscan');
    }
    if (resourceAccess[env.toLocaleLowerCase()]?.roles?.includes('js_jetflow')) {
        jetProducts.push('jetflow');
    }
    return jetProducts;
}

export const getTokenAvailableProduct = (accessTokenPayload: any, env: string) => {
    if (accessTokenPayload?.volumes) {

        const volumesOfEnv: any = accessTokenPayload?.volumes[env.toLowerCase()]
        return ([
            { jetscan: volumesOfEnv?.hasOwnProperty('jetscan') },
            { jetflow: volumesOfEnv?.hasOwnProperty('jetflow') }
        ]);
    }
    else {
        return ([
            { jetscan: false },
            { jetflow: false }
        ]);
    }
}