import '../../assets/scss/AppLayout.scss';

import { Layout } from 'antd';
import MobileMenu from './MobileMenu';
import NavBar from "./NavBar";
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import { useAuthInfo } from '../../utils/secure';
import { useState } from 'react';

export default function AppLayout() {
    const [barCollapsed, setBarCollapsed] = useState<boolean>(localStorage.getItem("portal-prefered-barCollapsed") === 'true' ?? false);
    // const [isFullWidth, setIsFullWidth] = useState<boolean>(localStorage.getItem("portal-prefered-layoutFullWidth") === 'true' ?? false);
    const isFullWidth:boolean = true;

    const { isAuthenticated } = useAuthInfo();

    function handleBarCollapse() {
        localStorage.setItem("portal-prefered-barCollapsed", String(!barCollapsed));
        setBarCollapsed(!barCollapsed);
    }
    // function toggleWidth() {
    //     localStorage.setItem("portal-prefered-layoutFullWidth", String(!isFullWidth));
    //     setIsFullWidth(!isFullWidth);
    // }

    return (
        <Layout className="layoutTop">
            <NavBar />
            {isAuthenticated && <MobileMenu />}
            <Layout className='appLayout'>
                {isAuthenticated && <Sidebar handleBarCollapse={handleBarCollapse} barCollapsed={barCollapsed} />}
                <Layout className={`mainContent ${(isAuthenticated ? 'isLogin' : '')} ${barCollapsed ? 'collapsed' : 'extended'}`}>
                    <div className={`mainContentWrapper ${isFullWidth ? 'fullWidth' : ''}`}>
                        <Outlet />
                    </div>
                </Layout>
            </Layout>
        </Layout>
    )
}