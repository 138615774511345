import { Button, Card, Flex, Space, Tabs } from 'antd';
import { EditOutlined, ExclamationCircleTwoTone, UpCircleOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom'
import { SecureWithLogin, ShowIfAccess } from '../../../utils/secure';
import { useEffect, useState } from 'react'

import LoadingSkeleton from '../../Layout/LoadingSkeleton';
import Moment from 'react-moment';
import PackagesList from '../packages/PackagesList';
import ServiceAccountList from '../serviceAccount/ServiceAccountList';
import TechInfos from '../common/TechInfos';
import UsersListWithNamespace from '../users/UsersListWithNamespace';
import { callBackEnd } from '../../../utils/backEndUtils';

export default function Namespace() {
    const { namespaceId } = useParams();

    const [loadingNamespace, setLoadingNamespace] = useState<boolean>(true);
    const [errorNamespace, setErrorNamespace] = useState<boolean>(false);
    const [namespace, setNamespace] = useState<Namespace | null>(null);
    const [loadingPackages, setLoadingPackages] = useState<boolean>(true);
    const [errorPackages, setErrorPackages] = useState<boolean>(false);
    const [packages, setPackages] = useState<Package[] | null>(null);
    const [loadingUsers, setLoadingUsers] = useState<boolean>(true);
    const [errorUsers, setErrorUsers] = useState<boolean>(false);
    const [users, setUsers] = useState<UserProfile[] | null>(null);
    const [serviceAccounts, setServiceAccounts] = useState<ServiceAccount[] | null>(null);
    const [loadingServiceAccounts, setLoadingServiceAccounts] = useState<boolean>(true);
    const [errorServiceAccounts, setErrorServiceAccounts] = useState<boolean>(false);


    useEffect(() => {
        async function getNamespace() {
            try {
                setLoadingNamespace(true);
                const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}`);
                setNamespace(response);
            } catch (error: any) {
                setErrorNamespace(error);
            } finally {
                setLoadingNamespace(false);
            }

        }
        async function getPackages() {
            try {
                setLoadingPackages(true);
                const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/packages`);
                let packages: any[] = [];
                if (Array.isArray(response)) {
                    packages = response;
                    //get volumes for each package
                    for (const packageItem of packages) {
                        const packageId = packageItem.uid;
                        const volumesResponse: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/packages/${packageId}/volumes`)
                            .catch((e: any) => { throw new Error(e) });

                        packageItem.volumes = volumesResponse;
                    }
                }
                setPackages(packages);

            } catch (error: any) {
                setErrorPackages(error);
            } finally {
                setLoadingPackages(false);
            }
        }
        async function getUsers() {
            try {
                setLoadingUsers(true);
                const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/users`);
                setUsers(response);

            } catch (error: any) {
                setErrorUsers(error);
            } finally {
                setLoadingUsers(false);
            }
        }
        async function getServiceAccounts() {
            try {
                setLoadingServiceAccounts(true);
                const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/service-accounts`);
                setServiceAccounts(response);

            } catch (error: any) {
                setErrorServiceAccounts(error);
            } finally {
                setLoadingServiceAccounts(false);
            }
        }
        getNamespace().catch((e: any) => console.log(e));
        getPackages().catch((e: any) => console.log(e));
        getUsers().catch((e: any) => console.log(e));
        getServiceAccounts().catch((e: any) => console.log(e));

    }, [namespaceId]);

    const findOwnerName = (ownerId: any) => {
        if (ownerId === undefined) {
            return (<>
                <ExclamationCircleTwoTone twoToneColor="#eb2f96" /> No owner found
            </>);
        }
        if (users !== null) {
            const owner = users.find((u: UserProfile) => u.uid === ownerId);
            return owner?.name;
        }
        return (<>
            <ExclamationCircleTwoTone twoToneColor="#eb2f96" /> No owner found toto
        </>);
    }

    return (
        <SecureWithLogin callbackPath="/namespaces" role={['crm_namespaces']}>
            {(!errorNamespace && !loadingNamespace && namespace !== null) ?

                <Card
                    title={<>Namespace: {namespace.label}</>}
                    className="namespace sectionCard"
                    extra={
                        <ShowIfAccess role={['crm_namespaces']}>
                            <Space size="middle">
                                <Link to={`/namespaces/edit/${namespaceId}`}>
                                    <Button type="primary" icon={<EditOutlined />}>
                                        Edit
                                    </Button>
                                </Link>
                                <Link to={`/namespaces/upgrade/${namespaceId}`}>
                                    <Button type="primary" icon={<UpCircleOutlined />}>
                                        Upgrade
                                    </Button>
                                </Link>
                            </Space>
                        </ShowIfAccess>}

                >
                    <Flex vertical gap="middle">
                        <TechInfos techProps={[
                            { name: 'Name', value: namespace.name },
                            { name: 'Type', value: namespace?.type },
                            { name: 'Email Domain', value: namespace.emailDomain },
                            { name: 'Owner', value: findOwnerName(namespace?.ownerId) },
                            { name: 'Uid', value: namespace.uid },
                            { name: 'On Premise enabled', value: namespace?.options?.onPremiseEnabled ? 'Yes' : 'No' },
                            { name: 'Last update', value: <Moment format="YYYY/MM/DD h:mm:ss a">{namespace?.updated}</Moment> },
                        ]}
                        />

                        <Tabs
                            defaultActiveKey='1'
                            items={[
                                ...(!errorUsers && !loadingUsers && users !== null && namespaceId !== undefined) ? [{ label: 'Users', children: <UsersListWithNamespace users={users} title='Users' namespaces={[]} ownerId={namespace.ownerId} />, key: '3' }] : [],
                                ...(!errorPackages && !loadingPackages && packages !== null && namespaceId !== undefined) ? [{ label: 'Packages', children: <PackagesList packages={packages} namespaceId={namespaceId} />, key: '1' }] : [],
                                ...(!errorServiceAccounts && !loadingServiceAccounts && serviceAccounts !== null && !errorPackages && !loadingPackages && packages !== null) ? [{ label: 'Service Accounts', children: <ServiceAccountList packages={packages} serviceAccounts={serviceAccounts} />, key: '2' }] : [],
                            ]
                            }
                        />
                    </Flex>
                </Card>

                :
                <LoadingSkeleton />
            }
        </SecureWithLogin >
    )
}
