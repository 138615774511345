import './../../assets/scss/News.scss';

import { Breadcrumb, Card, Flex, Space, Tag, } from 'antd';
import { Link, useParams } from 'react-router-dom';

import { Content } from 'antd/es/layout/layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HomeOutlined } from '@ant-design/icons';
import LoadingSkeleton from '../Layout/LoadingSkeleton';
import Markdown from 'react-markdown';
import Moment from 'react-moment';
import MotionBox from "../Layout/Animated/MotionBox";
import { PageHeader } from '../Layout/header/PageHeader';
// import SectionBanner from '../Layout/SectionBanner';
import { SecureWithLogin } from "../../utils/secure";
import ShowError from '../Layout/ShowError';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { getFormattedEnvironment } from '../../utils/getEnvironments';
import useNews from "../../hooks/useNews";

export default function NewsPage() {
    const { newsId } = useParams();
    const { news, loading, error } = useNews('dev', newsId);

    const showEnvPill = (env: string) => {
        if (env === 'all') return <span className={`envPill ${env}`}>All Environments</span>;

        const fomattedEnv: { name: string, label: string } | null = getFormattedEnvironment(env);

        if (!fomattedEnv) return null;

        return (
            <Tag className={`envPill ${env}`}>{fomattedEnv.label}</Tag>
        )
    }

    return (
        <SecureWithLogin callbackPath="/news" role={[]}>
            {(!loading && error === null && news !== null) ?
                <MotionBox>
                    <Content className="news">
                        {/* <SectionBanner title="News and Updates" type="news" /> */}
                        <PageHeader
                            title='Dashboard'
                            subtitle='Dashboard'
                            breadcrumbs={[
                                {title:<span><HomeOutlined/>&nbsp;Home</span>,href: '/',},
                                {title:<span>News</span>, href: '/news'},
                                {title:<span>{news.title}</span>}]}
                        />
                        <Flex vertical gap={"middle"} justify='center'>
                            <Breadcrumb items={[{ href: '/news', title: <Link to='/news'><FontAwesomeIcon icon={faArrowLeftLong} /> Back to the news page</Link>  }]} />
                            <Card
                                title={news.title}
                                key={news.id}
                                extra={
                                    <Space>
                                        {news.environment.map((env: string, index: string) => {
                                            return <div key={index}>{showEnvPill(env)}</div>;
                                        })
                                        }
                                        <Moment format="YYYY/MM/DD h:mm:ss a">
                                            {news.dateStart}
                                        </Moment>
                                    </Space>
                                }
                            >
                                <div className="content">
                                    <Markdown>{news.content}</Markdown>
                                </div>
                            </Card>
                        </Flex>
                    </Content>
                </MotionBox>

                :
                <>
                    {loading && <LoadingSkeleton />}
                    {error && <ShowError error={error} />}
                </>
            }
        </SecureWithLogin >


    )
}
