import { useEffect, useState } from "react";

import { callBackEnd } from "../utils/backEndUtils";

export default function useUsers() {
    const [users, setUsers] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);


    useEffect(() => {
        async function getUsers() {
            try {
                setLoading(true);
                const response = await callBackEnd('portal', 'get', 'users');

                if (Array.isArray(response)) {
                    setUsers(response);
                    setError(null);
                } else {
                    throw new Error('Invalid response from API');
                }
            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }

        getUsers().catch((e: any) => console.log(e));

    }, []);

    return { users, loading, error };
}
