import { useEffect, useState } from "react";

import { callBackEnd } from "../utils/backEndUtils";

export default function useFaq(env: string) {
    const [faq, setFaq] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);

    const targetEnv: string = `${env}`;

    useEffect(() => {
        async function getFaq() {
            try {
                setLoading(true);
                const content:any = await callBackEnd('directus', 'get', 'items/faq');
                const response: any = content.data.filter((item: any) => item.status === "published");
                setFaq(response);
                setError(null);
            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }
        getFaq().catch((e: any) => { console.log(e) });

    }, [targetEnv]);

    return { faq, loading, error };
}