import { SecureWithLogin, ShowIfAccess, useOidcAccessToken } from "../../../../utils/secure";
import { useEffect, useState } from "react";

import { Flex } from 'antd';
import Loading from "../../../Layout/Loading";
import MotionBox from "../../../Layout/Animated/MotionBox";
import NSAPendingUsersList from "./../NSAPendingUsersList";
import NSAUsersList from "../NSAUsersList";
import ShowError from "../../../Layout/ShowError";
import { callBackEnd } from "../../../../utils/backEndUtils";
import { useOutletContext } from "react-router-dom";

export default function NSAUsers() {
    const outletContext: { setHeaderTitle: React.Dispatch<React.SetStateAction<string>> } = useOutletContext();
    useEffect(() => {
        outletContext?.setHeaderTitle("My Users");
    }, [outletContext]);
    
    const { accessTokenPayload } = useOidcAccessToken();
    const [namespaceId, setNamespaceId] = useState<string>("");

    const [refresher, setRefresher] = useState<boolean>(false);

    const [loadingUsers, setLoadingUsers] = useState<boolean>(true);
    const [errorUsers, setErrorUsers] = useState<boolean>(false);
    const [users, setUsers] = useState<UserProfile[] | null>(null);
    const [loadingPendingUsers, setLoadingPendingUsers] = useState<boolean>(true);
    const [errorPendingUsers, setErrorPendingUsers] = useState<boolean>(false);
    const [pendingUsers, setPendingUsers] = useState<UserProfile[] | null>(null);

    useEffect(() => {
        if (accessTokenPayload?.namespaceId) {
            setNamespaceId(accessTokenPayload?.namespaceId);
        }
    }, [accessTokenPayload]);
    
    useEffect(() => {
        async function getUsers() {
            try {
                setLoadingUsers(true);
                const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/users`);
                setUsers(response);

            } catch (error: any) {
                setErrorUsers(error);
            } finally {
                setLoadingUsers(false);
            }
        }

        async function getPendingUsers() {
            try {
                setLoadingPendingUsers(true);
                const response: any = await callBackEnd('portal', 'get', `namespaces/${namespaceId}/join`);
                setPendingUsers(response);

            } catch (error: any) {
                setErrorPendingUsers(error);
            } finally {
                setLoadingPendingUsers(false);
            }
        }

        if (namespaceId !== '') {
            getUsers().catch((e: any) => console.log(e));
            getPendingUsers().catch((e: any) => console.log(e));
        }
    }, [namespaceId, refresher]);

    const refreshUsers = () => {
        setRefresher(!refresher);
    }

    return (

        <SecureWithLogin callbackPath="/nsadmin" role={['nsadmin_home']}>
            {/* {(!errorNamespace && !loadingNamespace && namespace !== null) ? */}
            <MotionBox>
                <Flex gap="middle" vertical>
                    <ShowIfAccess role={['nsadmin_users']}>
                        <ShowIfAccess role={['nsadmin_invites_users']}>
                            {pendingUsers !== null && pendingUsers.length > 0 &&
                                <>
                                    {(!errorPendingUsers && !loadingPendingUsers && pendingUsers !== null) ?
                                        <>
                                            {(namespaceId !== undefined) ?
                                                <NSAPendingUsersList users={pendingUsers} title='Pending Users' refreshUsers={refreshUsers} />
                                                :
                                                <Loading />
                                            }
                                        </>
                                        :
                                        <>
                                            {loadingUsers && <Loading />}
                                            {errorUsers && <ShowError error={errorUsers} />}
                                        </>
                                    }
                                </>
                            }
                        </ShowIfAccess>
                        {(!errorUsers && !loadingUsers && users !== null) ?
                            <>
                                {(namespaceId !== undefined) ?
                                    <NSAUsersList namespaceId={namespaceId} users={users} title='Users in Namespace' />
                                    :
                                    <Loading />
                                }
                            </>
                            :
                            <>
                                {loadingUsers && <Loading />}
                                {errorUsers && <ShowError error={errorUsers} />}
                            </>
                        }
                    </ShowIfAccess>

                </Flex>
            </MotionBox>
        </SecureWithLogin>
    )
}
